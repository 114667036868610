const startButton = document.getElementById("start-btn");
const nextButton = document.getElementById("next-btn");
const showMeButton = document.getElementById("show-me-how-btn");
const questionContainerElement = document.getElementById("question13-container");
const questionElement = document.getElementById("question13");
const answerButtonsElement = document.getElementById("answer-buttons13");
const ctaElement = document.getElementById("cta13");


const questions13 = [
  {
    question:
      "How does your team diffuse a difficult call?",
    answers: [
      { text: "Allow customers to vent and calm down", correct: true },
      { text: "Show compassion for the customer's experience", correct: true },
      { text: "Ask the customer to call back after they’ve calmed down", correct: true },
    ],
  },
  {
    question: "How many calls does your team take on a typical day?",
    answers: [
      { text: "1-5", correct: true },
      { text: "6-15", correct: true },
      { text: "16-30", correct: true },
      { text: "31+", correct: true },
    ],
  },
  {
    question: "Which describes your team best?",
    answers: [
      { text: "They don’t know what to do on a call", correct: true },
      { text: "They know what to do, but get stuck", correct: true },
      { text: "They always know what to do", correct: true },
    ],
  },
];


let shuffledQuestions13, currentQuestionIndex13;

startButton.addEventListener("click", startQuiz);
nextButton.addEventListener("click", () => {
  currentQuestionIndex13++;
  setNextQuestion();
  rotateGallery();
});

function startQuiz() {
  startButton.classList.add("hide");
  shuffledQuestions13 = questions13.sort();
  // () => Math.random() - 0.5;
  currentQuestionIndex13 = 0;
  questionContainerElement.classList.remove("hide");
  setNextQuestion();
}

function setNextQuestion() {
  resetState();
  if(currentQuestionIndex13 > questions13.length - 1){
    displaySuccess();
    return
  } 
  showQuestion(shuffledQuestions13[currentQuestionIndex13]);
}

function displaySuccess(){
  ctaElement.innerText =
  "Good news! Your team can improve their empathetic reasoning with DYL’s Call Monitor feature. Help managers know if your staff needs immediate help, so they can be better equipped and be more productive.";
  questionContainerElement.classList.add("hide");
  ctaElement.classList.remove("hide");
  showMeButton.classList.remove("hide");
}

function showQuestion(question) {
  questionElement.innerText = question.question;
  question.answers.forEach((answer) => {
    const button = document.createElement("h5");
    button.innerText = answer.text;
    button.classList.add("btn-block");
    button.classList.add("btn-primary");
    button.classList.add("p-2");
    button.classList.add("transition-3d-hover");
    button.classList.add("text-center");
    if (answer.correct) {
      button.dataset.correct = answer.correct;
    }
    button.addEventListener("click", selectAnswer);
    answerButtonsElement.appendChild(button);
  });
}

function resetState() {
  clearStatusClass(document.body);
  nextButton.classList.add("hide");
  while (answerButtonsElement.firstChild) {
    answerButtonsElement.removeChild(answerButtonsElement.firstChild);
  }
}

function selectAnswer(e) {
  const selectedButton = e.target;
  const correct = selectedButton.dataset.correct;
  setStatusClass(document.body, correct);
  Array.from(answerButtonsElement.children).forEach((button) => {
    setStatusClass(button, button.dataset.correct);
    selectedButton.style.backgroundColor = "green";
  });
    nextButton.classList.remove("hide");
}


function setStatusClass(element, correct) {
  clearStatusClass(element);
  if (correct) {
    element.classList.add("correct");
  } else {
    element.classList.add("wrong");
  }
}

function clearStatusClass(element) {
  element.classList.remove("correct");
  element.classList.remove("wrong");
}

let counter = 0,
  gallery = [
    "https://images.dyl.com/marketing-web/images/quiz/young-businessman-trying-to-pick-up-several-phones-upset.jpg",
    "https://images.dyl.com/marketing-web/images/quiz/boy-wearing-helmet-with-lightbulbs-lit.jpg",
    "https://images.dyl.com/marketing-web/images/quiz/woman-smiling-excited-with-yellow-background.jpg",
  ];
function rotateGallery() {
  document.getElementById("ImgGalleryCF").src = gallery[counter];
  counter++;
  if (counter >= gallery.length) {
    counter = 0;
  }
}

