const startButton = document.getElementById("start-btn");
const nextButton = document.getElementById("next-btn");
const showMeButton = document.getElementById("show-me-how-btn");
const questionContainerElement = document.getElementById("question10-container");
const questionElement = document.getElementById("question10");
const answerButtonsElement = document.getElementById("answer-buttons10");
const ctaElement = document.getElementById("cta10");


const questions10 = [
  {
    question:
      "Which describes your team best?",
    answers: [
      { text: "Always does its work", correct: true },
      { text: "Slacks off too much", correct: true },
      { text: "Needs some improvement", correct: true },
      { text: "Not Sure", correct: true },
    ],
  },
  {
    question: "How would your customers describe your team?",
    answers: [
      { text: "Extremely helpful and professional", correct: true },
      { text: "Average", correct: true },
      { text: "Needs improvement", correct: true },
      { text: "I don’t know", correct: true },
    ],
  },
  {
    question: "What do you need help with?",
    answers: [
      { text: "Tracking call performance", correct: true },
      { text: "Rep responsibility", correct: true },
      { text: "Both", correct: true },
    ],
  },
];


let shuffledQuestions10, currentQuestionIndex10;

startButton.addEventListener("click", startQuiz);
nextButton.addEventListener("click", () => {
  currentQuestionIndex10++;
  setNextQuestion();
  rotateGallery();
});

function startQuiz() {
  startButton.classList.add("hide");
  shuffledQuestions10 = questions10.sort();
  // () => Math.random() - 0.5;
  currentQuestionIndex10 = 0;
  questionContainerElement.classList.remove("hide");
  setNextQuestion();
}

function setNextQuestion() {
  resetState();
  if(currentQuestionIndex10 > questions10.length - 1){
    displaySuccess();
    return
  } 
  showQuestion(shuffledQuestions10[currentQuestionIndex10]);
}

function displaySuccess(){
  ctaElement.innerText =
  "Good news!  It looks like your team is a step ahead of the competition. DYL’s Call Center Dashboard feature helps you create responsibility through insightful data. Click below to find out how.";
  questionContainerElement.classList.add("hide");
  ctaElement.classList.remove("hide");
  showMeButton.classList.remove("hide");
}

function showQuestion(question) {
  questionElement.innerText = question.question;
  question.answers.forEach((answer) => {
    const button = document.createElement("h5");
    button.innerText = answer.text;
    button.classList.add("btn-block");
    button.classList.add("btn-primary");
    button.classList.add("p-2");
    button.classList.add("transition-3d-hover");
    button.classList.add("text-center");
    if (answer.correct) {
      button.dataset.correct = answer.correct;
    }
    button.addEventListener("click", selectAnswer);
    answerButtonsElement.appendChild(button);
  });
}

function resetState() {
  clearStatusClass(document.body);
  nextButton.classList.add("hide");
  while (answerButtonsElement.firstChild) {
    answerButtonsElement.removeChild(answerButtonsElement.firstChild);
  }
}

function selectAnswer(e) {
  const selectedButton = e.target;
  const correct = selectedButton.dataset.correct;
  setStatusClass(document.body, correct);
  Array.from(answerButtonsElement.children).forEach((button) => {
    setStatusClass(button, button.dataset.correct);
    selectedButton.style.backgroundColor = "green";
  });
    nextButton.classList.remove("hide");
}


function setStatusClass(element, correct) {
  clearStatusClass(element);
  if (correct) {
    element.classList.add("correct");
  } else {
    element.classList.add("wrong");
  }
}

function clearStatusClass(element) {
  element.classList.remove("correct");
  element.classList.remove("wrong");
}

let counter = 0,
  gallery = [
    "https://images.dyl.com/marketing-web/images/quiz/group-of-happy-young-professionals-at-desk.jpg",
    "https://images.dyl.com/marketing-web/images/quiz/group-of-professionals-putting-hands-together-in-circle.jpg",
    "https://images.dyl.com/marketing-web/images/quiz/woman-smiling-excited-with-yellow-background.jpg",
  ];
function rotateGallery() {
  document.getElementById("ImgGalleryCCQ").src = gallery[counter];
  counter++;
  if (counter >= gallery.length) {
    counter = 0;
  }
}

