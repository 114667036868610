const startButton = document.getElementById("start-btn");
const nextButton = document.getElementById("next-btn");
const showMeButton = document.getElementById("show-me-how-btn");
const questionContainerElement = document.getElementById("question7-container");
const questionElement = document.getElementById("question7");
const answerButtonsElement = document.getElementById("answer-buttons7");
const ctaElement = document.getElementById("cta7");


const questions7 = [
  {
    question:
      "What happens to your lead list if they don’t close?",
    answers: [
      { text: "Dump and move on", correct: true },
      { text: "Try calling again after a few weeks", correct: true },
      { text: "May attempt again if business is slow", correct: true },
    ],
  },
  {
    question: "What messaging do you use if you do reach out again?",
    answers: [
      { text: "We use the same sequence and script as before.", correct: true },
      { text: "We revise our sequence and script for the second time.", correct: true },
      { text: "We prefer to reach out to a fresh, new list.", correct: true },
    ],
  },
  {
    question: "What do you need help with?",
    answers: [
      { text: "Closing sales the first time around.", correct: true },
      { text: "A fresh way to remarket to old leads who didn’t buy the first time.", correct: true },
      { text: "All of the above", correct: true },
    ],
  },
];


let shuffledQuestions7, currentQuestionIndex7;

startButton.addEventListener("click", startQuiz);
nextButton.addEventListener("click", () => {
  currentQuestionIndex7++;
  setNextQuestion();
  rotateGallery();
});

function startQuiz() {
  startButton.classList.add("hide");
  shuffledQuestions7 = questions7.sort();
  // () => Math.random() - 0.5;
  currentQuestionIndex7 = 0;
  questionContainerElement.classList.remove("hide");
  setNextQuestion();
}

function setNextQuestion() {
  resetState();
  if(currentQuestionIndex7 > questions7.length - 1){
    displaySuccess();
    return
  } 
  showQuestion(shuffledQuestions7[currentQuestionIndex7]);
}

function displaySuccess(){
  ctaElement.innerText =
  "Good news! DYL’s Auto Remarketing tools let you use the information you already have to follow up with old leads and reopen new possibilities. Click below to find out how.";
  questionContainerElement.classList.add("hide");
  ctaElement.classList.remove("hide");
  showMeButton.classList.remove("hide");
}

function showQuestion(question) {
  questionElement.innerText = question.question;
  question.answers.forEach((answer) => {
    const button = document.createElement("h5");
    button.innerText = answer.text;
    button.classList.add("btn-block");
    button.classList.add("btn-primary");
    button.classList.add("p-2");
    button.classList.add("transition-3d-hover");
    button.classList.add("text-center");
    if (answer.correct) {
      button.dataset.correct = answer.correct;
    }
    button.addEventListener("click", selectAnswer);
    answerButtonsElement.appendChild(button);
  });
}

function resetState() {
  clearStatusClass(document.body);
  nextButton.classList.add("hide");
  while (answerButtonsElement.firstChild) {
    answerButtonsElement.removeChild(answerButtonsElement.firstChild);
  }
}

function selectAnswer(e) {
  const selectedButton = e.target;
  const correct = selectedButton.dataset.correct;
  setStatusClass(document.body, correct);
  Array.from(answerButtonsElement.children).forEach((button) => {
    setStatusClass(button, button.dataset.correct);
    selectedButton.style.backgroundColor = "green";
  });
    nextButton.classList.remove("hide");
}


function setStatusClass(element, correct) {
  clearStatusClass(element);
  if (correct) {
    element.classList.add("correct");
  } else {
    element.classList.add("wrong");
  }
}

function clearStatusClass(element) {
  element.classList.remove("correct");
  element.classList.remove("wrong");
}

let counter = 0,
  gallery = [
    "https://images.dyl.com/marketing-web/images/quiz/old-banana-new-banana-on-yellow-background.jpg",
    "https://images.dyl.com/marketing-web/images/quiz/pug-wearing-glasses-holding-money-and-piggy-bank.jpg",
    "https://images.dyl.com/marketing-web/images/quiz/woman-smiling-excited-with-yellow-background.jpg",
  ];
function rotateGallery() {
  document.getElementById("ImgGalleryART").src = gallery[counter];
  counter++;
  if (counter >= gallery.length) {
    counter = 0;
  }
}

