const startButton = document.getElementById("start-btn");
const nextButton = document.getElementById("next-btn");
const showMeButton = document.getElementById("show-me-how-btn");
const questionContainerElement = document.getElementById("question3-container");
const questionElement = document.getElementById("question3");
const answerButtonsElement = document.getElementById("answer-buttons3");
const ctaElement = document.getElementById("cta3");
const imgOne = document.getElementsByClassName("question3-one");
const imgTwo = document.getElementsByClassName("question3-two");
const imgThree = document.getElementsByClassName("question3-three");

const questions3 = [
  {
    question:
      "How do you track the effectiveness of your marketing?",
    answers: [
      { text: "Manually - Excel Sheet, Docs, etc.", correct: true },
      { text: "Google Analytics", correct: true },
      { text: "Other", correct: true },
      { text: "Don't know", correct: true },
    ],
  },
  {
    question: "How long does it take to pivot your marketing and sales efforts?",
    answers: [
      { text: "About a month after gathering data", correct: true },
      { text: "A few weeks", correct: true },
      { text: "Don’t really track that", correct: true },
    ],
  },
  {
    question: "What do you need help with?",
    answers: [
      { text: "Improving our marketing and sales campaigns", correct: true },
      { text: "Improving Data Collection", correct: true },
      { text: "All of the above", correct: true },
    ],
  },
];


let shuffledQuestions3, currentQuestionIndex3;

startButton.addEventListener("click", startQuiz);
nextButton.addEventListener("click", () => {
  currentQuestionIndex3++;
  setNextQuestion();
  rotateGallery();
});

function startQuiz() {
  startButton.classList.add("hide");
  shuffledQuestions3 = questions3.sort();
  currentQuestionIndex3 = 0;
  questionContainerElement.classList.remove("hide");
  setNextQuestion();
}

function setNextQuestion() {
  resetState();
  if(currentQuestionIndex3 > questions3.length - 1){
    displaySuccess();
    return
  } 
  showQuestion(shuffledQuestions3[currentQuestionIndex3]);
}

function displaySuccess(){
  ctaElement.innerText =
  "Good news! DYL’s Analytic Call Tracking educates team members and managers in real time as calls come in, so they know which strategies are working and what needs are most important. Click below to find out how.";
  questionContainerElement.classList.add("hide");
  ctaElement.classList.remove("hide");
  showMeButton.classList.remove("hide");
}

function showQuestion(question) {
  questionElement.innerText = question.question;
  question.answers.forEach((answer) => {
    const button = document.createElement("h5");
    button.innerText = answer.text;
    button.classList.add("btn-block");
    button.classList.add("btn-primary");
    button.classList.add("p-2");
    button.classList.add("transition-3d-hover");
    button.classList.add("text-center");
    if (answer.correct) {
      button.dataset.correct = answer.correct;
    }
    button.addEventListener("click", selectAnswer);
    answerButtonsElement.appendChild(button);
  });
}

function resetState() {
  clearStatusClass(document.body);
  nextButton.classList.add("hide");
  while (answerButtonsElement.firstChild) {
    answerButtonsElement.removeChild(answerButtonsElement.firstChild);
  }
}

function selectAnswer(e) {
  const selectedButton = e.target;
  const correct = selectedButton.dataset.correct;
  setStatusClass(document.body, correct);
  Array.from(answerButtonsElement.children).forEach((button) => {
    setStatusClass(button, button.dataset.correct);
    selectedButton.style.backgroundColor = "green";
  });
    nextButton.classList.remove("hide");
}

function setStatusClass(element, correct) {
  clearStatusClass(element);
  if (correct) {
    element.classList.add("correct");
  } else {
    element.classList.add("wrong");
  }
}

function clearStatusClass(element) {
  element.classList.remove("correct");
  element.classList.remove("wrong");
}

let counter = 0,
  gallery = [
    "https://images.dyl.com/marketing-web/images/quiz/blue-hourglass-on-sand.jpg",
    "https://images.dyl.com/marketing-web/images/quiz/person-using-building-blocks.jpg",
    "https://images.dyl.com/marketing-web/images/quiz/woman-smiling-excited-with-yellow-background.jpg",

  ];
function rotateGallery() {
  document.getElementById("ImgGalleryACT").src = gallery[counter];
  counter++;
  if (counter >= gallery.length) {
    counter = 0;
  }
}

