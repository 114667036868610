const startButton = document.getElementById("start-btn");
const nextButton = document.getElementById("next-btn");
const showMeButton = document.getElementById("show-me-how-btn");
const questionContainerElement = document.getElementById("question22-container");
const questionElement = document.getElementById("question22");
const answerButtonsElement = document.getElementById("answer-buttons22");
const ctaElement = document.getElementById("cta22");


const questions22 = [
  {
    question:
      "What happens to incoming calls when your representative is busy?",
    answers: [
      { text: "The caller is sent to voicemail.", correct: true },
      { text: "A receptionist notes the call and passes it on.", correct: true },
      { text: "The caller needs to call back later.", correct: true },
    ],
  },
  {
    question: "Worse case scenario: how long can it take for a call to get through to your company?",
    answers: [
      { text: "A couple of minutes.", correct: true },
      { text: "Several minutes.", correct: true },
      { text: "Unsure.", correct: true },
    ],
  },
  {
    question: "What do you need help with?",
    answers: [
      { text: "Customer support", correct: true },
      { text: "Lead response", correct: true },
      { text: "Teamwork", correct: true },
    ],
  },
];


let shuffledQuestions22, currentQuestionIndex22;

startButton.addEventListener("click", startQuiz);
nextButton.addEventListener("click", () => {
  currentQuestionIndex22++;
  setNextQuestion();
  rotateGallery();
});

function startQuiz() {
  startButton.classList.add("hide");
  shuffledQuestions22 = questions22.sort();
  // () => Math.random() - 0.5;
  currentQuestionIndex22 = 0;
  questionContainerElement.classList.remove("hide");
  setNextQuestion();
}

function setNextQuestion() {
  resetState();
  if(currentQuestionIndex22 > questions22.length - 1){
    displaySuccess();
    return
  } 
  showQuestion(shuffledQuestions22[currentQuestionIndex22]);
}

function displaySuccess(){
  ctaElement.innerText =
  "Good news! DYL’s Call Waiting signals your team when calls come in, so they can easily transfer or pick up. Click below to find out how.";
  questionContainerElement.classList.add("hide");
  ctaElement.classList.remove("hide");
  showMeButton.classList.remove("hide");
}

function showQuestion(question) {
  questionElement.innerText = question.question;
  question.answers.forEach((answer) => {
    const button = document.createElement("h5");
    button.innerText = answer.text;
    button.classList.add("btn-block");
    button.classList.add("btn-primary");
    button.classList.add("p-2");
    button.classList.add("transition-3d-hover");
    button.classList.add("text-center");
    if (answer.correct) {
      button.dataset.correct = answer.correct;
    }
    button.addEventListener("click", selectAnswer);
    answerButtonsElement.appendChild(button);
  });
}

function resetState() {
  clearStatusClass(document.body);
  nextButton.classList.add("hide");
  while (answerButtonsElement.firstChild) {
    answerButtonsElement.removeChild(answerButtonsElement.firstChild);
  }
}

function selectAnswer(e) {
  const selectedButton = e.target;
  const correct = selectedButton.dataset.correct;
  setStatusClass(document.body, correct);
  Array.from(answerButtonsElement.children).forEach((button) => {
    setStatusClass(button, button.dataset.correct);
    selectedButton.style.backgroundColor = "green";
  });
    nextButton.classList.remove("hide");
}


function setStatusClass(element, correct) {
  clearStatusClass(element);
  if (correct) {
    element.classList.add("correct");
  } else {
    element.classList.add("wrong");
  }
}

function clearStatusClass(element) {
  element.classList.remove("correct");
  element.classList.remove("wrong");
}

let counter = 0,
  gallery = [
    "https://images.dyl.com/marketing-web/images/quiz/man-with-sign-back-in-5-minutes.jpg",
    "https://images.dyl.com/marketing-web/images/quiz/group-of-people-hugging-on-each-other-backs-turned.jpg",
    "https://images.dyl.com/marketing-web/images/quiz/woman-smiling-excited-with-yellow-background.jpg",
  ];
function rotateGallery() {
  document.getElementById("ImgGalleryCW").src = gallery[counter];
  counter++;
  if (counter >= gallery.length) {
    counter = 0;
  }
}

