//Font Awesome
import '../bundle/font-awesome/scss/fontawesome.scss'
import '../bundle/font-awesome/scss/solid.scss'
import '../bundle/font-awesome/scss/brands.scss'
import '../bundle/font-awesome/scss/light.scss'
import '../bundle/font-awesome/scss/regular.scss'
import '../bundle/font-awesome/scss/duotone.scss'

//Front UI
import '../bundle/scss/_variables.scss'
import '../bundle/front-ui/vendor/hs-mega-menu/src/scss/hs-mega-menu.scss'
import '../bundle/front-ui/vendor/slick-carousel/slick/slick.css'
import '../bundle/scss/aos.scss'
import '../bundle/front-ui/scss/theme.scss'


//DYL Specific UI
import '../bundle/scss/landing-pages.scss'
import '../bundle/scss/industries.scss'
import '../bundle/scss/advantages.scss'
import '../bundle/scss/product.scss'
import '../bundle/scss/area-codes.scss'


//Stream UI
import '../bundle/css/stream-styles.css'

//Bootstrap UI
import '../bundle/scss/bootstrap-addons.scss'
import '../bundle/scss/styles-global-UI.scss'

//Typing UI
import '../bundle/css/typed.css'

//Media Query Bootstrap
import '../bundle/scss/sm-breakpoints.scss'
import '../bundle/scss/md-breakpoints.scss'
import '../bundle/scss/lg-breakpoints.scss'
import '../bundle/scss/xl-breakpoints.scss'

///////////////Javascript//////////////////
///////////////////////////////////////////
//jQuery, Bootstrap, Popper
import 'jquery'
import '../bundle/js/jquery-migrate'
import 'bootstrap'

//Component Vendors
import '../bundle/front-ui/vendor/appear'

//Global Theme Setting and Calls
import '../bundle/js/pre-loader'
import '../bundle/js/front-init'

//Quiz App 
import '../bundle/js/quizzes/agentMonitoringQuiz.js'
import '../bundle/js/quizzes/agentStatusQuiz.js'
import '../bundle/js/quizzes/appointmentRemindersQuiz.js'
import '../bundle/js/quizzes/analyticCallTrackingQuiz.js'
import '../bundle/js/quizzes/automatedTextMessagingQuiz.js'
import '../bundle/js/quizzes/automaticCallDistributionQuiz.js'
import '../bundle/js/quizzes/autoRemarketingQuiz.js'
import '../bundle/js/quizzes/businessSmsQuiz.js'
import '../bundle/js/quizzes/callBargingQuiz.js'
import '../bundle/js/quizzes/callCenterQuiz.js'
import '../bundle/js/quizzes/callDetailRecordsQuiz.js'
import '../bundle/js/quizzes/callForwardingQuiz.js'
import '../bundle/js/quizzes/callMonitoringQuiz.js'
import '../bundle/js/quizzes/callParkQuiz.js'
import '../bundle/js/quizzes/callQueuesQuiz.js'
import '../bundle/js/quizzes/callRecordingQuiz.js'
import '../bundle/js/quizzes/callRoutingQuiz.js'
import '../bundle/js/quizzes/callSchedulingQuiz.js'
import '../bundle/js/quizzes/callSwitchQuiz.js'
import '../bundle/js/quizzes/callTagsQuiz.js'
import '../bundle/js/quizzes/callTransferQuiz.js'
import '../bundle/js/quizzes/callWaitingQuiz.js'
import '../bundle/js/quizzes/callWhisperingQuiz.js'
import '../bundle/js/quizzes/campaignAnalyticsQuiz.js'
import '../bundle/js/quizzes/clickToCallQuiz.js'
import '../bundle/js/quizzes/conferenceBridgeQuiz.js'
