const startButton = document.getElementById("start-btn");
const nextButton = document.getElementById("next-btn");
const showMeButton = document.getElementById("show-me-how-btn");
const questionContainerElement = document.getElementById("question14-container");
const questionElement = document.getElementById("question14");
const answerButtonsElement = document.getElementById("answer-buttons14");
const ctaElement = document.getElementById("cta14");


const questions14 = [
  {
    question:
      "Which area is most important to you?",
    answers: [
      { text: "Closing more sales", correct: true },
      { text: "Improved customer service", correct: true },
      { text: "Both are equally important", correct: true },
    ],
  },
  {
    question: "How do you ensure callers are being taken care of?",
    answers: [
      { text: "Ongoing training and hope for the best", correct: true },
      { text: "Surveys and polls", correct: true },
      { text: "I don’t really know for sure", correct: true },
    ],
  },
  {
    question: "How would you describe your current phone system experience?",
    answers: [
      { text: "Not very efficient", correct: true },
      { text: "Missing some features", correct: true },
      { text: "Looking to replace it", correct: true },
    ],
  },
];


let shuffledQuestions14, currentQuestionIndex14;

startButton.addEventListener("click", startQuiz);
nextButton.addEventListener("click", () => {
  currentQuestionIndex14++;
  setNextQuestion();
  rotateGallery();
});

function startQuiz() {
  startButton.classList.add("hide");
  shuffledQuestions14 = questions14.sort();
  // () => Math.random() - 0.5;
  currentQuestionIndex14 = 0;
  questionContainerElement.classList.remove("hide");
  setNextQuestion();
}

function setNextQuestion() {
  resetState();
  if(currentQuestionIndex14 > questions14.length - 1){
    displaySuccess();
    return
  } 
  showQuestion(shuffledQuestions14[currentQuestionIndex14]);
}

function displaySuccess(){
  ctaElement.innerText =
  "Good news! DYL’s Call Park is just one of over 80 features designed to make sales and service more efficient. Click below to find out how.";
  questionContainerElement.classList.add("hide");
  ctaElement.classList.remove("hide");
  showMeButton.classList.remove("hide");
}

function showQuestion(question) {
  questionElement.innerText = question.question;
  question.answers.forEach((answer) => {
    const button = document.createElement("h5");
    button.innerText = answer.text;
    button.classList.add("btn-block");
    button.classList.add("btn-primary");
    button.classList.add("p-2");
    button.classList.add("transition-3d-hover");
    button.classList.add("text-center");
    if (answer.correct) {
      button.dataset.correct = answer.correct;
    }
    button.addEventListener("click", selectAnswer);
    answerButtonsElement.appendChild(button);
  });
}

function resetState() {
  clearStatusClass(document.body);
  nextButton.classList.add("hide");
  while (answerButtonsElement.firstChild) {
    answerButtonsElement.removeChild(answerButtonsElement.firstChild);
  }
}

function selectAnswer(e) {
  const selectedButton = e.target;
  const correct = selectedButton.dataset.correct;
  setStatusClass(document.body, correct);
  Array.from(answerButtonsElement.children).forEach((button) => {
    setStatusClass(button, button.dataset.correct);
    selectedButton.style.backgroundColor = "green";
  });
    nextButton.classList.remove("hide");
}


function setStatusClass(element, correct) {
  clearStatusClass(element);
  if (correct) {
    element.classList.add("correct");
  } else {
    element.classList.add("wrong");
  }
}

function clearStatusClass(element) {
  element.classList.remove("correct");
  element.classList.remove("wrong");
}

let counter = 0,
  gallery = [
    "https://images.dyl.com/marketing-web/images/quiz/man-hugging-grandmother-with-cup-in-hand.jpg",
    "https://images.dyl.com/marketing-web/images/quiz/sad-middle-aged-woman-staring-at-desk.jpg",
    "https://images.dyl.com/marketing-web/images/quiz/woman-smiling-excited-with-yellow-background.jpg",
  ];
function rotateGallery() {
  document.getElementById("ImgGalleryCP").src = gallery[counter];
  counter++;
  if (counter >= gallery.length) {
    counter = 0;
  }
}

