const startButton = document.getElementById("start-btn");
const nextButton = document.getElementById("next-btn");
const showMeButton = document.getElementById("show-me-how-btn");
const questionContainerElement = document.getElementById("question9-container");
const questionElement = document.getElementById("question9");
const answerButtonsElement = document.getElementById("answer-buttons9");
const ctaElement = document.getElementById("cta9");


const questions9 = [
  {
    question:
      "How many customer service agents are on your team?",
    answers: [
      { text: "1-4", correct: true },
      { text: "5-9", correct: true },
      { text: "10+", correct: true },
    ],
  },
  {
    question: "How do customers feel after a call?",
    answers: [
      { text: "Fantastic", correct: true },
      { text: "Decent", correct: true },
      { text: "Not good", correct: true },
      { text: "I don’t know", correct: true },
    ],
  },
  {
    question: "How do you keep track of customer service?",
    answers: [
      { text: "Listen in", correct: true },
      { text: "Told by staff members", correct: true },
      { text: "Both", correct: true },
    ],
  },
];


let shuffledQuestions9, currentQuestionIndex9;

startButton.addEventListener("click", startQuiz);
nextButton.addEventListener("click", () => {
  currentQuestionIndex9++;
  setNextQuestion();
  rotateGallery();
});

function startQuiz() {
  startButton.classList.add("hide");
  shuffledQuestions9 = questions9.sort();
  // () => Math.random() - 0.5;
  currentQuestionIndex9 = 0;
  questionContainerElement.classList.remove("hide");
  setNextQuestion();
}

function setNextQuestion() {
  resetState();
  if(currentQuestionIndex9 > questions9.length - 1){
    displaySuccess();
    return
  } 
  showQuestion(shuffledQuestions9[currentQuestionIndex9]);
}

function displaySuccess(){
  ctaElement.innerText =
  "Sounds like you’re taking the right steps and are ready to take your service to the next level. DYL’s Call Barging feature enables management to track how team members are performing. Our platform also includes several features that can increase repeat customer volume with just a few clicks. Learn about them here:";
  questionContainerElement.classList.add("hide");
  ctaElement.classList.remove("hide");
  showMeButton.classList.remove("hide");
}

function showQuestion(question) {
  questionElement.innerText = question.question;
  question.answers.forEach((answer) => {
    const button = document.createElement("h5");
    button.innerText = answer.text;
    button.classList.add("btn-block");
    button.classList.add("btn-primary");
    button.classList.add("p-2");
    button.classList.add("transition-3d-hover");
    button.classList.add("text-center");
    if (answer.correct) {
      button.dataset.correct = answer.correct;
    }
    button.addEventListener("click", selectAnswer);
    answerButtonsElement.appendChild(button);
  });
}

function resetState() {
  clearStatusClass(document.body);
  nextButton.classList.add("hide");
  while (answerButtonsElement.firstChild) {
    answerButtonsElement.removeChild(answerButtonsElement.firstChild);
  }
}

function selectAnswer(e) {
  const selectedButton = e.target;
  const correct = selectedButton.dataset.correct;
  setStatusClass(document.body, correct);
  Array.from(answerButtonsElement.children).forEach((button) => {
    setStatusClass(button, button.dataset.correct);
    selectedButton.style.backgroundColor = "green";
  });
    nextButton.classList.remove("hide");
}


function setStatusClass(element, correct) {
  clearStatusClass(element);
  if (correct) {
    element.classList.add("correct");
  } else {
    element.classList.add("wrong");
  }
}

function clearStatusClass(element) {
  element.classList.remove("correct");
  element.classList.remove("wrong");
}

let counter = 0,
  gallery = [
    "https://images.dyl.com/marketing-web/images/quiz/couple-man-and-woman-jumping-for-joy-grey-background.jpg",
    "https://images.dyl.com/marketing-web/images/quiz/male-spy-listening-on-ear-piece.jpg",
    "https://images.dyl.com/marketing-web/images/quiz/woman-smiling-excited-with-yellow-background.jpg",
  ];
function rotateGallery() {
  document.getElementById("ImgGalleryART").src = gallery[counter];
  counter++;
  if (counter >= gallery.length) {
    counter = 0;
  }
}

