const startButton = document.getElementById("start-btn");
const nextButton = document.getElementById("next-btn");
const showMeButton = document.getElementById("show-me-how-btn");
const questionContainerElement = document.getElementById("question8-container");
const questionElement = document.getElementById("question8");
const answerButtonsElement = document.getElementById("answer-buttons8");
const ctaElement = document.getElementById("cta8");


const questions8 = [
  {
    question:
      "How do you primarily contact customers?",
    answers: [
      { text: "By Phone", correct: true },
      { text: "By Email", correct: true },
      { text: "By SMS", correct: true },
    ],
  },
  {
    question: "What percent of customers respond to your communication?",
    answers: [
      { text: "A little over 50%", correct: true },
      { text: "Less than 50%", correct: true },
      { text: "Don’t know", correct: true },
    ],
  },
  {
    question: "What do you need help with?",
    answers: [
      { text: "Customer Response Rate", correct: true },
      { text: "Delivery Time of Messages", correct: true },
      { text: "Customer Trust", correct: true },
    ],
  },
];


let shuffledQuestions8, currentQuestionIndex8;

startButton.addEventListener("click", startQuiz);
nextButton.addEventListener("click", () => {
  currentQuestionIndex8++;
  setNextQuestion();
  rotateGallery();
});

function startQuiz() {
  startButton.classList.add("hide");
  shuffledQuestions8 = questions8.sort();
  // () => Math.random() - 0.5;
  currentQuestionIndex8 = 0;
  questionContainerElement.classList.remove("hide");
  setNextQuestion();
}

function setNextQuestion() {
  resetState();
  if(currentQuestionIndex8 > questions8.length - 1){
    displaySuccess();
    return
  } 
  showQuestion(shuffledQuestions8[currentQuestionIndex8]);
}

function displaySuccess(){
  ctaElement.innerText =
  "Good news! DYL’s Business SMS incorporates customizable Workflows, so you can automate your communications for increased engagement. Click below to find out how.";
  questionContainerElement.classList.add("hide");
  ctaElement.classList.remove("hide");
  showMeButton.classList.remove("hide");
}

function showQuestion(question) {
  questionElement.innerText = question.question;
  question.answers.forEach((answer) => {
    const button = document.createElement("h5");
    button.innerText = answer.text;
    button.classList.add("btn-block");
    button.classList.add("btn-primary");
    button.classList.add("p-2");
    button.classList.add("transition-3d-hover");
    button.classList.add("text-center");
    if (answer.correct) {
      button.dataset.correct = answer.correct;
    }
    button.addEventListener("click", selectAnswer);
    answerButtonsElement.appendChild(button);
  });
}

function resetState() {
  clearStatusClass(document.body);
  nextButton.classList.add("hide");
  while (answerButtonsElement.firstChild) {
    answerButtonsElement.removeChild(answerButtonsElement.firstChild);
  }
}

function selectAnswer(e) {
  const selectedButton = e.target;
  const correct = selectedButton.dataset.correct;
  setStatusClass(document.body, correct);
  Array.from(answerButtonsElement.children).forEach((button) => {
    setStatusClass(button, button.dataset.correct);
    selectedButton.style.backgroundColor = "green";
  });
    nextButton.classList.remove("hide");
}


function setStatusClass(element, correct) {
  clearStatusClass(element);
  if (correct) {
    element.classList.add("correct");
  } else {
    element.classList.add("wrong");
  }
}

function clearStatusClass(element) {
  element.classList.remove("correct");
  element.classList.remove("wrong");
}

let counter = 0,
  gallery = [
    "https://images.dyl.com/marketing-web/images/quiz/pie-chart-50-50-on-chalk-board.jpg",
    "https://images.dyl.com/marketing-web/images/quiz/stacking-rocks-showing-progression.jpg",
    "https://images.dyl.com/marketing-web/images/quiz/woman-smiling-excited-with-yellow-background.jpg",
  ];
function rotateGallery() {
  document.getElementById("ImgGalleryART").src = gallery[counter];
  counter++;
  if (counter >= gallery.length) {
    counter = 0;
  }
}

