const startButton = document.getElementById("start-btn");
const nextButton = document.getElementById("next-btn");
const showMeButton = document.getElementById("show-me-how-btn");
const questionContainerElement = document.getElementById("question15-container");
const questionElement = document.getElementById("question15");
const answerButtonsElement = document.getElementById("answer-buttons15");
const ctaElement = document.getElementById("cta15");


const questions15 = [
  {
    question:
      "How long are your wait times?",
    answers: [
      { text: "5 minutes or more", correct: true },
      { text: "1 minute to 5 minutes", correct: true },
      { text: "Less than 1 minute", correct: true },
      { text: "Don't Know", correct: true },
    ],
  },
  {
    question: "How do customers route themselves to the correct department?",
    answers: [
      { text: "Through an over the phone selection process", correct: true },
      { text: "Speaking into the phone", correct: true },
      { text: "They can’t", correct: true },
      { text: "I don’t really know for sure", correct: true },
    ],
  },
  {
    question: "What do you need help with?",
    answers: [
      { text: "Improving customer experience over the phone", correct: true },
      { text: "Improving internal processes for customer service", correct: true },
    ],
  },
];


let shuffledQuestions15, currentQuestionIndex15;

startButton.addEventListener("click", startQuiz);
nextButton.addEventListener("click", () => {
  currentQuestionIndex15++;
  setNextQuestion();
  rotateGallery();
});

function startQuiz() {
  startButton.classList.add("hide");
  shuffledQuestions15 = questions15.sort();
  // () => Math.random() - 0.5;
  currentQuestionIndex15 = 0;
  questionContainerElement.classList.remove("hide");
  setNextQuestion();
}

function setNextQuestion() {
  resetState();
  if(currentQuestionIndex15 > questions15.length - 1){
    displaySuccess();
    return
  } 
  showQuestion(shuffledQuestions15[currentQuestionIndex15]);
}

function displaySuccess(){
  ctaElement.innerText =
  "Good news! DYL’s Call Queues improves organization for customer service teams. Reps know who they need to speak with next, from who called in first. Want to find out how your business can improve incoming call structure? Click below to find out how.";
  questionContainerElement.classList.add("hide");
  ctaElement.classList.remove("hide");
  showMeButton.classList.remove("hide");
}

function showQuestion(question) {
  questionElement.innerText = question.question;
  question.answers.forEach((answer) => {
    const button = document.createElement("h5");
    button.innerText = answer.text;
    button.classList.add("btn-block");
    button.classList.add("btn-primary");
    button.classList.add("p-2");
    button.classList.add("transition-3d-hover");
    button.classList.add("text-center");
    if (answer.correct) {
      button.dataset.correct = answer.correct;
    }
    button.addEventListener("click", selectAnswer);
    answerButtonsElement.appendChild(button);
  });
}

function resetState() {
  clearStatusClass(document.body);
  nextButton.classList.add("hide");
  while (answerButtonsElement.firstChild) {
    answerButtonsElement.removeChild(answerButtonsElement.firstChild);
  }
}

function selectAnswer(e) {
  const selectedButton = e.target;
  const correct = selectedButton.dataset.correct;
  setStatusClass(document.body, correct);
  Array.from(answerButtonsElement.children).forEach((button) => {
    setStatusClass(button, button.dataset.correct);
    selectedButton.style.backgroundColor = "green";
  });
    nextButton.classList.remove("hide");
}


function setStatusClass(element, correct) {
  clearStatusClass(element);
  if (correct) {
    element.classList.add("correct");
  } else {
    element.classList.add("wrong");
  }
}

function clearStatusClass(element) {
  element.classList.remove("correct");
  element.classList.remove("wrong");
}

let counter = 0,
  gallery = [
    "https://images.dyl.com/marketing-web/images/quiz/empty-desert-road-forever.jpg",
    "https://images.dyl.com/marketing-web/images/quiz/woman-opening-arms-on-mountain-looking-at-hot-air-balloons.jpg",
    "https://images.dyl.com/marketing-web/images/quiz/woman-smiling-excited-with-yellow-background.jpg",
  ];
function rotateGallery() {
  document.getElementById("ImgGalleryCQ").src = gallery[counter];
  counter++;
  if (counter >= gallery.length) {
    counter = 0;
  }
}

