const startButton = document.getElementById("start-btn");
const nextButton = document.getElementById("next-btn");
const showMeButton = document.getElementById("show-me-how-btn");
const questionContainerElement = document.getElementById("question16-container");
const questionElement = document.getElementById("question16");
const answerButtonsElement = document.getElementById("answer-buttons16");
const ctaElement = document.getElementById("cta16");


const questions16 = [
  {
    question:
      "What kind of team do you have?",
    answers: [
      { text: "Sales", correct: true },
      { text: "Customer Service", correct: true },
    ],
  },
  {
    question: "How long is your training program?",
    answers: [
      { text: "Less than a week", correct: true },
      { text: "1-2 Weeks", correct: true },
      { text: "3+ Weeks", correct: true },
    ],
  },
  {
    question: "How often do your teams answer client or prospect questions successfully?",
    answers: [
      { text: "About 50% of the time", correct: true },
      { text: "Not sure", correct: true },
    ],
  },
];


let shuffledQuestions16, currentQuestionIndex16;

startButton.addEventListener("click", startQuiz);
nextButton.addEventListener("click", () => {
  currentQuestionIndex16++;
  setNextQuestion();
  rotateGallery();
});

function startQuiz() {
  startButton.classList.add("hide");
  shuffledQuestions16 = questions16.sort();
  // () => Math.random() - 0.5;
  currentQuestionIndex16 = 0;
  questionContainerElement.classList.remove("hide");
  setNextQuestion();
}

function setNextQuestion() {
  resetState();
  if(currentQuestionIndex16 > questions16.length - 1){
    displaySuccess();
    return
  } 
  showQuestion(shuffledQuestions16[currentQuestionIndex16]);
}

function displaySuccess(){
  ctaElement.innerText =
  "Good news! DYL’s Call Recording feature is accompanied by unlimited call recording and activity reports, so managers can ensure staff is reliably answering client and prospect questions. They can also take advantage of the Whisper function to assist Sales staff, putting them one step closer to the sale. Click below to find out how.";
  questionContainerElement.classList.add("hide");
  ctaElement.classList.remove("hide");
  showMeButton.classList.remove("hide");
}

function showQuestion(question) {
  questionElement.innerText = question.question;
  question.answers.forEach((answer) => {
    const button = document.createElement("h5");
    button.innerText = answer.text;
    button.classList.add("btn-block");
    button.classList.add("btn-primary");
    button.classList.add("p-2");
    button.classList.add("transition-3d-hover");
    button.classList.add("text-center");
    if (answer.correct) {
      button.dataset.correct = answer.correct;
    }
    button.addEventListener("click", selectAnswer);
    answerButtonsElement.appendChild(button);
  });
}

function resetState() {
  clearStatusClass(document.body);
  nextButton.classList.add("hide");
  while (answerButtonsElement.firstChild) {
    answerButtonsElement.removeChild(answerButtonsElement.firstChild);
  }
}

function selectAnswer(e) {
  const selectedButton = e.target;
  const correct = selectedButton.dataset.correct;
  setStatusClass(document.body, correct);
  Array.from(answerButtonsElement.children).forEach((button) => {
    setStatusClass(button, button.dataset.correct);
    selectedButton.style.backgroundColor = "green";
  });
    nextButton.classList.remove("hide");
}


function setStatusClass(element, correct) {
  clearStatusClass(element);
  if (correct) {
    element.classList.add("correct");
  } else {
    element.classList.add("wrong");
  }
}

function clearStatusClass(element) {
  element.classList.remove("correct");
  element.classList.remove("wrong");
}

let counter = 0,
  gallery = [
    "https://images.dyl.com/marketing-web/images/quiz/fitness-group-working-out-with-dumbbells.jpg",
    "https://images.dyl.com/marketing-web/images/quiz/woman-opening-arms-on-mountain-looking-at-hot-air-balloons.jpg",
    "https://images.dyl.com/marketing-web/images/quiz/soccer-coach-high-fiving-boy-player-black-and-white.jpg",
  ];
function rotateGallery() {
  document.getElementById("ImgGalleryCR").src = gallery[counter];
  counter++;
  if (counter >= gallery.length) {
    counter = 0;
  }
}

