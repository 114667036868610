const startButton = document.getElementById("start-btn");
const nextButton = document.getElementById("next-btn");
const showMeButton = document.getElementById("show-me-how-btn");
const questionContainerElement = document.getElementById("question12-container");
const questionElement = document.getElementById("question12");
const answerButtonsElement = document.getElementById("answer-buttons12");
const ctaElement = document.getElementById("cta12");


const questions12 = [
  {
    question:
      "How often are your employees out of office?",
    answers: [
      { text: "Every day", correct: true },
      { text: "Every week", correct: true },
      { text: "Every month", correct: true },
      { text: "Never", correct: true },
    ],
  },
  {
    question: "Are your customers satisfied with your customer service process?",
    answers: [
      { text: "They’re impressed", correct: true },
      { text: "They’re satisfied", correct: true },
      { text: "It could be improved", correct: true },
      { text: "Don’t know", correct: true },
    ],
  },
  {
    question: "What do you need help with?",
    answers: [
      { text: "Improving customer experience over the phone", correct: true },
      { text: "Improving communication with employees out of office", correct: true },
      { text: "Needing a call forwarding service", correct: true },
    ],
  },
];


let shuffledQuestions12, currentQuestionIndex12;

startButton.addEventListener("click", startQuiz);
nextButton.addEventListener("click", () => {
  currentQuestionIndex12++;
  setNextQuestion();
  rotateGallery();
});

function startQuiz() {
  startButton.classList.add("hide");
  shuffledQuestions12 = questions12.sort();
  // () => Math.random() - 0.5;
  currentQuestionIndex12 = 0;
  questionContainerElement.classList.remove("hide");
  setNextQuestion();
}

function setNextQuestion() {
  resetState();
  if(currentQuestionIndex12 > questions12.length - 1){
    displaySuccess();
    return
  } 
  showQuestion(shuffledQuestions12[currentQuestionIndex12]);
}

function displaySuccess(){
  ctaElement.innerText =
  "Good news! DYL’s Call Forwarding makes sure your employees can be out of office, on the road, or remote. Without having to use the Call Forwarding process, DYL lets your employees connect to the platform, anywhere in the world with an internet connection. Click below to find out how.";
  questionContainerElement.classList.add("hide");
  ctaElement.classList.remove("hide");
  showMeButton.classList.remove("hide");
}

function showQuestion(question) {
  questionElement.innerText = question.question;
  question.answers.forEach((answer) => {
    const button = document.createElement("h5");
    button.innerText = answer.text;
    button.classList.add("btn-block");
    button.classList.add("btn-primary");
    button.classList.add("p-2");
    button.classList.add("transition-3d-hover");
    button.classList.add("text-center");
    if (answer.correct) {
      button.dataset.correct = answer.correct;
    }
    button.addEventListener("click", selectAnswer);
    answerButtonsElement.appendChild(button);
  });
}

function resetState() {
  clearStatusClass(document.body);
  nextButton.classList.add("hide");
  while (answerButtonsElement.firstChild) {
    answerButtonsElement.removeChild(answerButtonsElement.firstChild);
  }
}

function selectAnswer(e) {
  const selectedButton = e.target;
  const correct = selectedButton.dataset.correct;
  setStatusClass(document.body, correct);
  Array.from(answerButtonsElement.children).forEach((button) => {
    setStatusClass(button, button.dataset.correct);
    selectedButton.style.backgroundColor = "green";
  });
    nextButton.classList.remove("hide");
}


function setStatusClass(element, correct) {
  clearStatusClass(element);
  if (correct) {
    element.classList.add("correct");
  } else {
    element.classList.add("wrong");
  }
}

function clearStatusClass(element) {
  element.classList.remove("correct");
  element.classList.remove("wrong");
}

let counter = 0,
  gallery = [
    "https://images.dyl.com/marketing-web/images/quiz/black-couple-jumping-with-thumb-up-yellow-background.jpg",
    "https://images.dyl.com/marketing-web/images/quiz/business-man-looking-off-into-sunset-on-mountains.jpg",
    "https://images.dyl.com/marketing-web/images/quiz/woman-smiling-excited-with-yellow-background.jpg",
  ];
function rotateGallery() {
  document.getElementById("ImgGalleryCF").src = gallery[counter];
  counter++;
  if (counter >= gallery.length) {
    counter = 0;
  }
}

