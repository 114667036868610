const startButton = document.getElementById("start-btn");
const nextButton = document.getElementById("next-btn");
const showMeButton = document.getElementById("show-me-how-btn");
const questionContainerElement = document.getElementById("question17-container");
const questionElement = document.getElementById("question17");
const answerButtonsElement = document.getElementById("answer-buttons17");
const ctaElement = document.getElementById("cta17");


const questions17 = [
  {
    question:
      "Does your team…?",
    answers: [
      { text: "Miss several calls a day", correct: true },
      { text: "Miss 1 - 2 calls a week", correct: true },
      { text: "Never miss calls", correct: true },
    ],
  },
  {
    question: "Do they…",
    answers: [
      { text: "Exceed customer expectations", correct: true },
      { text: "Meet customer expectations", correct: true },
      { text: "Miss customer expectations", correct: true },
    ],
  },
  {
    question: "What do you want to improve?",
    answers: [
      { text: "Team Organization", correct: true },
      { text: "Team Accountability", correct: true },
      { text: "Phone System Reliability", correct: true },
    ],
  },
];


let shuffledQuestions17, currentQuestionIndex17;

startButton.addEventListener("click", startQuiz);
nextButton.addEventListener("click", () => {
  currentQuestionIndex17++;
  setNextQuestion();
  rotateGallery();
});

function startQuiz() {
  startButton.classList.add("hide");
  shuffledQuestions17 = questions17.sort();
  // () => Math.random() - 0.5;
  currentQuestionIndex17 = 0;
  questionContainerElement.classList.remove("hide");
  setNextQuestion();
}

function setNextQuestion() {
  resetState();
  if(currentQuestionIndex17 > questions17.length - 1){
    displaySuccess();
    return
  } 
  showQuestion(shuffledQuestions17[currentQuestionIndex17]);
}

function displaySuccess(){
  ctaElement.innerText =
  "Good news! DYL’s Call Routing Service equips your team with everything they need to reach out to clients, get in touch quickly, and find answers fast. Click below to find out how!";
  questionContainerElement.classList.add("hide");
  ctaElement.classList.remove("hide");
  showMeButton.classList.remove("hide");
}

function showQuestion(question) {
  questionElement.innerText = question.question;
  question.answers.forEach((answer) => {
    const button = document.createElement("h5");
    button.innerText = answer.text;
    button.classList.add("btn-block");
    button.classList.add("btn-primary");
    button.classList.add("p-2");
    button.classList.add("transition-3d-hover");
    button.classList.add("text-center");
    if (answer.correct) {
      button.dataset.correct = answer.correct;
    }
    button.addEventListener("click", selectAnswer);
    answerButtonsElement.appendChild(button);
  });
}

function resetState() {
  clearStatusClass(document.body);
  nextButton.classList.add("hide");
  while (answerButtonsElement.firstChild) {
    answerButtonsElement.removeChild(answerButtonsElement.firstChild);
  }
}

function selectAnswer(e) {
  const selectedButton = e.target;
  const correct = selectedButton.dataset.correct;
  setStatusClass(document.body, correct);
  Array.from(answerButtonsElement.children).forEach((button) => {
    setStatusClass(button, button.dataset.correct);
    selectedButton.style.backgroundColor = "green";
  });
    nextButton.classList.remove("hide");
}


function setStatusClass(element, correct) {
  clearStatusClass(element);
  if (correct) {
    element.classList.add("correct");
  } else {
    element.classList.add("wrong");
  }
}

function clearStatusClass(element) {
  element.classList.remove("correct");
  element.classList.remove("wrong");
}

let counter = 0,
  gallery = [
    "https://images.dyl.com/marketing-web/images/quiz/reality-and-expectations-sign.jpg",
    "https://images.dyl.com/marketing-web/images/quiz/construction-worker-putting-brick-on-mortar.jpg",
    "https://images.dyl.com/marketing-web/images/quiz/woman-smiling-excited-with-yellow-background.jpg",
  ];
function rotateGallery() {
  document.getElementById("ImgGalleryCRS").src = gallery[counter];
  counter++;
  if (counter >= gallery.length) {
    counter = 0;
  }
}

