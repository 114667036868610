const startButton = document.getElementById("start-btn");
const nextButton = document.getElementById("next-btn");
const showMeButton = document.getElementById("show-me-how-btn");
const questionContainerElement = document.getElementById("question-container");
const questionElement = document.getElementById("question");
const answerButtonsElement = document.getElementById("answer-buttons");
const ctaElement = document.getElementById("cta");


const questions = [
  {
    question:
      "Do you have a…",
    answers: [
      { text: "Sales team", correct: true },
      { text: "Customer service team", correct: true },
      { text: "Both", correct: true },
    ],
  },
  {
    question: "What is your performance evaluation process like?",
    answers: [
      { text: "Peer-to-peer", correct: true },
      { text: "Informal", correct: true },
      { text: "Don’t really have one", correct: true },
    ],
  },
  {
    question: "What do you need help with?",
    answers: [
      { text: "Monitoring staff performance", correct: true },
      { text: "Improving customer experience", correct: true },
      { text: "Tracking sales success", correct: true },
      { text: "All of the above", correct: true },
    ],
  },
];


let shuffledQuestions, currentQuestionIndex;

startButton.addEventListener("click", startQuiz);
nextButton.addEventListener("click", () => {
  currentQuestionIndex++;
  setNextQuestion();
  rotateGallery();
});

function startQuiz() {
  
  startButton.classList.add("hide");
  shuffledQuestions = questions.sort();
  currentQuestionIndex = 0;
  questionContainerElement.classList.remove("hide");
  setNextQuestion();
}

function setNextQuestion() {
  resetState();
  if(currentQuestionIndex > questions.length - 1){
    displaySuccess();
    return
  } 
  showQuestion(shuffledQuestions[currentQuestionIndex]);
}

function displaySuccess(){
  ctaElement.innerText =
  "Good news! DYL’s Agent Monitoring tools provide your managers with data and recorded calls to review benchmarks and guide sales teams for greater success. Click below to find out how.";
  questionContainerElement.classList.add("hide");
  ctaElement.classList.remove("hide");
  showMeButton.classList.remove("hide");
}


function showQuestion(question) {
  questionElement.innerText = question.question;
  question.answers.forEach((answer) => {
    const button = document.createElement("h5");
    button.innerText = answer.text;
    button.classList.add("btn-block");
    button.classList.add("btn-primary");
    button.classList.add("p-2");
    button.classList.add("transition-3d-hover");
    button.classList.add("text-center");
    if (answer.correct) {
      button.dataset.correct = answer.correct;
    }
    button.addEventListener("click", selectAnswer);
    answerButtonsElement.appendChild(button);
  });
}

function resetState() {
  clearStatusClass(document.body);
  nextButton.classList.add("hide");
  while (answerButtonsElement.firstChild) {
    answerButtonsElement.removeChild(answerButtonsElement.firstChild);
  }
}

function selectAnswer(e) {
  const selectedButton = e.target;
  const correct = selectedButton.dataset.correct;
  setStatusClass(document.body, correct);
  Array.from(answerButtonsElement.children).forEach((button) => {
    setStatusClass(button, button.dataset.correct);
    selectedButton.style.backgroundColor = "green";
  });
    nextButton.classList.remove("hide");
}

function setStatusClass(element, correct) {
  clearStatusClass(element);
  if (correct) {
    element.classList.add("correct");
  } else {
    element.classList.add("wrong");
  }
}

function clearStatusClass(element) {
  element.classList.remove("correct");
  element.classList.remove("wrong");
}

let counter = 0,
  gallery = [
    "https://images.dyl.com/marketing-web/images/quiz/two-people-on-mountain-holding-hands.jpg",
    "https://images.dyl.com/marketing-web/images/quiz/funny-basketball-coach-blue-background.jpg",
    "https://images.dyl.com/marketing-web/images/quiz/woman-smiling-excited-with-yellow-background.jpg",
  ];
function rotateGallery() {
  document.getElementById("ImgGalleryAM").src = gallery[counter];
  counter++;
  if (counter >= gallery.length) {
    counter = 0;
  }
}

