const startButton = document.getElementById("start-btn");
const nextButton = document.getElementById("next-btn");
const showMeButton = document.getElementById("show-me-how-btn");
const questionContainerElement = document.getElementById("question26-container");
const questionElement = document.getElementById("question26");
const answerButtonsElement = document.getElementById("answer-buttons26");
const ctaElement = document.getElementById("cta26");


const questions26 = [
  {
    question:
      "How do you typically hold staff meetings?",
    answers: [
      { text: "Video platform", correct: true },
      { text: "In person", correct: true },
      { text: "Over the phone", correct: true },
    ],
  },
  {
    question: "How many people can be on the call?",
    answers: [
      { text: "Up to 10", correct: true },
      { text: "5-9", correct: true },
      { text: "Less than 5", correct: true },
    ],
  },
  {
    question: "Which are you looking to improve?",
    answers: [
      { text: "Internal Communication", correct: true },
      { text: "Customer Communication", correct: true },
    ],
  },
];


let shuffledQuestions26, currentQuestionIndex26;

startButton.addEventListener("click", startQuiz);
nextButton.addEventListener("click", () => {
  currentQuestionIndex26++;
  setNextQuestion();
  rotateGallery();
});

function startQuiz() {
  startButton.classList.add("hide");
  shuffledQuestions26 = questions26.sort();
  // () => Math.random() - 0.5;
  currentQuestionIndex26 = 0;
  questionContainerElement.classList.remove("hide");
  setNextQuestion();
}

function setNextQuestion() {
  resetState();
  if(currentQuestionIndex26 > questions26.length - 1){
    displaySuccess();
    return
  } 
  showQuestion(shuffledQuestions26[currentQuestionIndex26]);
}

function displaySuccess(){
  ctaElement.innerText =
  "Good news! DYL’s Conference Bridge feature can be used by multiple reps and managers to meet with customers or prospects about opportunities and concerns. Click below to find out more.";
  questionContainerElement.classList.add("hide");
  ctaElement.classList.remove("hide");
  showMeButton.classList.remove("hide");
}

function showQuestion(question) {
  questionElement.innerText = question.question;
  question.answers.forEach((answer) => {
    const button = document.createElement("h5");
    button.innerText = answer.text;
    button.classList.add("btn-block");
    button.classList.add("btn-primary");
    button.classList.add("p-2");
    button.classList.add("transition-3d-hover");
    button.classList.add("text-center");
    if (answer.correct) {
      button.dataset.correct = answer.correct;
    }
    button.addEventListener("click", selectAnswer);
    answerButtonsElement.appendChild(button);
  });
}

function resetState() {
  clearStatusClass(document.body);
  nextButton.classList.add("hide");
  while (answerButtonsElement.firstChild) {
    answerButtonsElement.removeChild(answerButtonsElement.firstChild);
  }
}

function selectAnswer(e) {
  const selectedButton = e.target;
  const correct = selectedButton.dataset.correct;
  setStatusClass(document.body, correct);
  Array.from(answerButtonsElement.children).forEach((button) => {
    setStatusClass(button, button.dataset.correct);
    selectedButton.style.backgroundColor = "green";
  });
    nextButton.classList.remove("hide");
}


function setStatusClass(element, correct) {
  clearStatusClass(element);
  if (correct) {
    element.classList.add("correct");
  } else {
    element.classList.add("wrong");
  }
}

function clearStatusClass(element) {
  element.classList.remove("correct");
  element.classList.remove("wrong");
}

let counter = 0,
  gallery = [
    "https://images.dyl.com/marketing-web/images/quiz/video-conferencing-with-multiple-people.jpg",
    "https://images.dyl.com/marketing-web/images/quiz/business-woman-whispering-in-business-mans-ear.jpg",
    "https://images.dyl.com/marketing-web/images/quiz/woman-smiling-excited-with-yellow-background.jpg",
  ];
function rotateGallery() {
  document.getElementById("ImgGalleryCB").src = gallery[counter];
  counter++;
  if (counter >= gallery.length) {
    counter = 0;
  }
}

