const startButton = document.getElementById("start-btn");
const nextButton = document.getElementById("next-btn");
const showMeButton = document.getElementById("show-me-how-btn");
const questionContainerElement = document.getElementById("question19-container");
const questionElement = document.getElementById("question19");
const answerButtonsElement = document.getElementById("answer-buttons19");
const ctaElement = document.getElementById("cta19");


const questions19 = [
  {
    question:
      "How quickly does your team answer incoming calls?",
    answers: [
      { text: "Mostly within seconds", correct: true },
      { text: "More than 10 seconds", correct: true },
      { text: "More than 30 seconds", correct: true },
      { text: "Don’t know", correct: true },
    ],
  },
  {
    question: "How does your team handle multiple incoming calls?",
    answers: [
      { text: "Hangup current call then answer the new call", correct: true },
      { text: "Pickup two phones at once", correct: true },
      { text: "Send the incoming caller to voicemail", correct: true },
      { text: "Don’t know", correct: true },
    ],
  },
  {
    question: "What do you need help with?",
    answers: [
      { text: "Improving wait times for incoming callers", correct: true },
      { text: "Ensuring staff members don’t drop calls", correct: true },
    ],
  },
];


let shuffledQuestions19, currentQuestionIndex19;

startButton.addEventListener("click", startQuiz);
nextButton.addEventListener("click", () => {
  currentQuestionIndex19++;
  setNextQuestion();
  rotateGallery();
});

function startQuiz() {
  startButton.classList.add("hide");
  shuffledQuestions19 = questions19.sort();
  // () => Math.random() - 0.5;
  currentQuestionIndex19 = 0;
  questionContainerElement.classList.remove("hide");
  setNextQuestion();
}

function setNextQuestion() {
  resetState();
  if(currentQuestionIndex19 > questions19.length - 1){
    displaySuccess();
    return
  } 
  showQuestion(shuffledQuestions19[currentQuestionIndex19]);
}

function displaySuccess(){
  ctaElement.innerText =
  "Good news! DYL’s Call Switch ensures callers don’t have to waitlong to get in touch with a staff member. Your employees can answer multiple calls at one time, then choose what to do next. Click below to find out how.";
  questionContainerElement.classList.add("hide");
  ctaElement.classList.remove("hide");
  showMeButton.classList.remove("hide");
}

function showQuestion(question) {
  questionElement.innerText = question.question;
  question.answers.forEach((answer) => {
    const button = document.createElement("h5");
    button.innerText = answer.text;
    button.classList.add("btn-block");
    button.classList.add("btn-primary");
    button.classList.add("p-2");
    button.classList.add("transition-3d-hover");
    button.classList.add("text-center");
    if (answer.correct) {
      button.dataset.correct = answer.correct;
    }
    button.addEventListener("click", selectAnswer);
    answerButtonsElement.appendChild(button);
  });
}

function resetState() {
  clearStatusClass(document.body);
  nextButton.classList.add("hide");
  while (answerButtonsElement.firstChild) {
    answerButtonsElement.removeChild(answerButtonsElement.firstChild);
  }
}

function selectAnswer(e) {
  const selectedButton = e.target;
  const correct = selectedButton.dataset.correct;
  setStatusClass(document.body, correct);
  Array.from(answerButtonsElement.children).forEach((button) => {
    setStatusClass(button, button.dataset.correct);
    selectedButton.style.backgroundColor = "green";
  });
    nextButton.classList.remove("hide");
}


function setStatusClass(element, correct) {
  clearStatusClass(element);
  if (correct) {
    element.classList.add("correct");
  } else {
    element.classList.add("wrong");
  }
}

function clearStatusClass(element) {
  element.classList.remove("correct");
  element.classList.remove("wrong");
}

let counter = 0,
  gallery = [
    "https://images.dyl.com/marketing-web/images/quiz/student-drowning-in-books.jpg",
    "https://images.dyl.com/marketing-web/images/quiz/person-in-water-reaching-for-lifebuoy.jpg",
    "https://images.dyl.com/marketing-web/images/quiz/woman-smiling-excited-with-yellow-background.jpg",
  ];
function rotateGallery() {
  document.getElementById("ImgGalleryCS").src = gallery[counter];
  counter++;
  if (counter >= gallery.length) {
    counter = 0;
  }
}

