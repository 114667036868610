const startButton = document.getElementById("start-btn");
const nextButton = document.getElementById("next-btn");
const showMeButton = document.getElementById("show-me-how-btn");
const questionContainerElement = document.getElementById("question20-container");
const questionElement = document.getElementById("question20");
const answerButtonsElement = document.getElementById("answer-buttons20");
const ctaElement = document.getElementById("cta20");


const questions20 = [
  {
    question:
      "How is your customer data stored?",
    answers: [
      { text: "In file folders at each rep’s desk", correct: true },
      { text: "On a server", correct: true },
      { text: "Agents store them on their computer desktops", correct: true },
    ],
  },
  {
    question: "How does your staff spend most of its time?",
    answers: [
      { text: "Answering calls", correct: true },
      { text: "Making outbound calls", correct: true },
      { text: "Filing paperwork and administrative duties", correct: true },
    ],
  },
  {
    question: "What do you need help with?",
    answers: [
      { text: "Team Organization", correct: true },
      { text: "Staff Accountability", correct: true },
    ],
  },
];


let shuffledQuestions20, currentQuestionIndex20;

startButton.addEventListener("click", startQuiz);
nextButton.addEventListener("click", () => {
  currentQuestionIndex20++;
  setNextQuestion();
  rotateGallery();
});

function startQuiz() {
  startButton.classList.add("hide");
  shuffledQuestions20 = questions20.sort();
  // () => Math.random() - 0.5;
  currentQuestionIndex20 = 0;
  questionContainerElement.classList.remove("hide");
  setNextQuestion();
}

function setNextQuestion() {
  resetState();
  if(currentQuestionIndex20 > questions20.length - 1){
    displaySuccess();
    return
  } 
  showQuestion(shuffledQuestions20[currentQuestionIndex20]);
}

function displaySuccess(){
  ctaElement.innerText =
  "Good news! On average, businesses save over $1,000 with call organization tools. DYL’s Call Tags can help your staff track down customer information faster in order to provide better customer service. Click below to find out how.";
  questionContainerElement.classList.add("hide");
  ctaElement.classList.remove("hide");
  showMeButton.classList.remove("hide");
}

function showQuestion(question) {
  questionElement.innerText = question.question;
  question.answers.forEach((answer) => {
    const button = document.createElement("h5");
    button.innerText = answer.text;
    button.classList.add("btn-block");
    button.classList.add("btn-primary");
    button.classList.add("p-2");
    button.classList.add("transition-3d-hover");
    button.classList.add("text-center");
    if (answer.correct) {
      button.dataset.correct = answer.correct;
    }
    button.addEventListener("click", selectAnswer);
    answerButtonsElement.appendChild(button);
  });
}

function resetState() {
  clearStatusClass(document.body);
  nextButton.classList.add("hide");
  while (answerButtonsElement.firstChild) {
    answerButtonsElement.removeChild(answerButtonsElement.firstChild);
  }
}

function selectAnswer(e) {
  const selectedButton = e.target;
  const correct = selectedButton.dataset.correct;
  setStatusClass(document.body, correct);
  Array.from(answerButtonsElement.children).forEach((button) => {
    setStatusClass(button, button.dataset.correct);
    selectedButton.style.backgroundColor = "green";
  });
    nextButton.classList.remove("hide");
}


function setStatusClass(element, correct) {
  clearStatusClass(element);
  if (correct) {
    element.classList.add("correct");
  } else {
    element.classList.add("wrong");
  }
}

function clearStatusClass(element) {
  element.classList.remove("correct");
  element.classList.remove("wrong");
}

let counter = 0,
  gallery = [
    "https://images.dyl.com/marketing-web/images/quiz/warehouse-workers-moving-boxes-and-merchandise.jpg",
    "https://images.dyl.com/marketing-web/images/quiz/person-in-water-reaching-for-lifebuoy.jpg",
    "https://images.dyl.com/marketing-web/images/quiz/woman-smiling-excited-with-yellow-background.jpg",
  ];
function rotateGallery() {
  document.getElementById("ImgGalleryCT").src = gallery[counter];
  counter++;
  if (counter >= gallery.length) {
    counter = 0;
  }
}

