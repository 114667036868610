const startButton = document.getElementById("start-btn");
const nextButton = document.getElementById("next-btn");
const showMeButton = document.getElementById("show-me-how-btn");
const questionContainerElement = document.getElementById("question25-container");
const questionElement = document.getElementById("question25");
const answerButtonsElement = document.getElementById("answer-buttons25");
const ctaElement = document.getElementById("cta25");


const questions25 = [
  {
    question:
      "Does your website have a way for a visitor to contact you immediately?",
    answers: [
      { text: "No", correct: true },
      { text: "Not immediately (contact form and phone number)", correct: true },
    ],
  },
  {
    question: "Does your website have webchat?",
    answers: [
      { text: "Yes, but it’s not live", correct: true },
      { text: "No, it’s too expensive", correct: true },
      { text: "Software, not online", correct: true },
      { text: "Don’t have one", correct: true },
    ],
  },
];


let shuffledQuestions25, currentQuestionIndex25;

startButton.addEventListener("click", startQuiz);
nextButton.addEventListener("click", () => {
  currentQuestionIndex25++;
  setNextQuestion();
  rotateGallery();
});

function startQuiz() {
  startButton.classList.add("hide");
  shuffledQuestions25 = questions25.sort();
  // () => Math.random() - 0.5;
  currentQuestionIndex25 = 0;
  questionContainerElement.classList.remove("hide");
  setNextQuestion();
}

function setNextQuestion() {
  resetState();
  if(currentQuestionIndex25 > questions25.length - 1){
    displaySuccess();
    return
  } 
  showQuestion(shuffledQuestions25[currentQuestionIndex25]);
}

function displaySuccess(){
  ctaElement.innerText =
  "Good news! DYL’s Click To Call is an affordable solution for connecting with prospects, leads, and customers immediately. Click below to find out how.";
  questionContainerElement.classList.add("hide");
  ctaElement.classList.remove("hide");
  showMeButton.classList.remove("hide");
}

function showQuestion(question) {
  questionElement.innerText = question.question;
  question.answers.forEach((answer) => {
    const button = document.createElement("h5");
    button.innerText = answer.text;
    button.classList.add("btn-block");
    button.classList.add("btn-primary");
    button.classList.add("p-2");
    button.classList.add("transition-3d-hover");
    button.classList.add("text-center");
    if (answer.correct) {
      button.dataset.correct = answer.correct;
    }
    button.addEventListener("click", selectAnswer);
    answerButtonsElement.appendChild(button);
  });
}

function resetState() {
  clearStatusClass(document.body);
  nextButton.classList.add("hide");
  while (answerButtonsElement.firstChild) {
    answerButtonsElement.removeChild(answerButtonsElement.firstChild);
  }
}

function selectAnswer(e) {
  const selectedButton = e.target;
  const correct = selectedButton.dataset.correct;
  setStatusClass(document.body, correct);
  Array.from(answerButtonsElement.children).forEach((button) => {
    setStatusClass(button, button.dataset.correct);
    selectedButton.style.backgroundColor = "green";
  });
    nextButton.classList.remove("hide");
}


function setStatusClass(element, correct) {
  clearStatusClass(element);
  if (correct) {
    element.classList.add("correct");
  } else {
    element.classList.add("wrong");
  }
}

function clearStatusClass(element) {
  element.classList.remove("correct");
  element.classList.remove("wrong");
}

let counter = 0,
  gallery = [
    "https://images.dyl.com/marketing-web/images/quiz/livechat-on-mobile-phone-conversation.jpg",
    "https://images.dyl.com/marketing-web/images/quiz/woman-smiling-excited-with-yellow-background.jpg",
  ];
function rotateGallery() {
  document.getElementById("ImgGalleryCTC").src = gallery[counter];
  counter++;
  if (counter >= gallery.length) {
    counter = 0;
  }
}

