const startButton = document.getElementById("start-btn");
const nextButton = document.getElementById("next-btn");
const showMeButton = document.getElementById("show-me-how-btn");
const questionContainerElement = document.getElementById("question6-container");
const questionElement = document.getElementById("question6");
const answerButtonsElement = document.getElementById("answer-buttons6");
const ctaElement = document.getElementById("cta6");


const questions6 = [
  {
    question:
      "What type of phone set-up do you have?",
    answers: [
      { text: "One main line with extensions", correct: true },
      { text: "Multiple phone lines", correct: true },
      { text: "Don’t know", correct: true },
    ],
  },
  {
    question: "What is the average wait time for someone calling your business?",
    answers: [
      { text: "A couple of minutes", correct: true },
      { text: "30 seconds - a minute", correct: true },
      { text: "Don’t know", correct: true },
    ],
  },
  {
    question: "What do you need help with?",
    answers: [
      { text: "Improving our Sales team's contact rates", correct: true },
      { text: "Decreasing customer wait times on calls", correct: true },
      { text: "All of the above", correct: true },
    ],
  },
];


let shuffledQuestions6, currentQuestionIndex6;

startButton.addEventListener("click", startQuiz);
nextButton.addEventListener("click", () => {
  currentQuestionIndex6++;
  setNextQuestion();
  rotateGallery();
});

function startQuiz() {
  startButton.classList.add("hide");
  shuffledQuestions6 = questions6.sort();
  // () => Math.random() - 0.5;
  currentQuestionIndex6 = 0;
  questionContainerElement.classList.remove("hide");
  setNextQuestion();
}

function setNextQuestion() {
  resetState();
  if(currentQuestionIndex6 > questions6.length - 1){
    displaySuccess();
    return
  } 
  showQuestion(shuffledQuestions6[currentQuestionIndex6]);
}

function displaySuccess(){
  ctaElement.innerText =
  "Good news! DYL’s Automatic Call Distribution decreases customer wait time by immediately notifying agents via round robin, simultaneous ring, or other option of your choice. Click below to find out how.";
  questionContainerElement.classList.add("hide");
  ctaElement.classList.remove("hide");
  showMeButton.classList.remove("hide");
}

function showQuestion(question) {
  questionElement.innerText = question.question;
  question.answers.forEach((answer) => {
    const button = document.createElement("h5");
    button.innerText = answer.text;
    button.classList.add("btn-block");
    button.classList.add("btn-primary");
    button.classList.add("p-2");
    button.classList.add("transition-3d-hover");
    button.classList.add("text-center");
    if (answer.correct) {
      button.dataset.correct = answer.correct;
    }
    button.addEventListener("click", selectAnswer);
    answerButtonsElement.appendChild(button);
  });
}

function resetState() {
  clearStatusClass(document.body);
  nextButton.classList.add("hide");
  while (answerButtonsElement.firstChild) {
    answerButtonsElement.removeChild(answerButtonsElement.firstChild);
  }
}

function selectAnswer(e) {
  const selectedButton = e.target;
  const correct = selectedButton.dataset.correct;
  setStatusClass(document.body, correct);
  Array.from(answerButtonsElement.children).forEach((button) => {
    setStatusClass(button, button.dataset.correct);
    selectedButton.style.backgroundColor = "green";
  });
    nextButton.classList.remove("hide");
}


function setStatusClass(element, correct) {
  clearStatusClass(element);
  if (correct) {
    element.classList.add("correct");
  } else {
    element.classList.add("wrong");
  }
}

function clearStatusClass(element) {
  element.classList.remove("correct");
  element.classList.remove("wrong");
}

let counter = 0,
  gallery = [
    "https://images.dyl.com/marketing-web/images/quiz/puppy-dog-eyes-hanging-over-green-edge.jpg",
    "https://images.dyl.com/marketing-web/images/quiz/pug-wearing-glasses-holding-money-and-piggy-bank.jpg",
    "https://images.dyl.com/marketing-web/images/quiz/woman-smiling-excited-with-yellow-background.jpg",
  ];
function rotateGallery() {
  document.getElementById("ImgGalleryACD").src = gallery[counter];
  counter++;
  if (counter >= gallery.length) {
    counter = 0;
  }
}

