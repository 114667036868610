const startButton = document.getElementById("start-btn");
const nextButton = document.getElementById("next-btn");
const showMeButton = document.getElementById("show-me-how-btn");
const questionContainerElement = document.getElementById("question2-container");
const questionElement = document.getElementById("question2");
const answerButtonsElement = document.getElementById("answer-buttons2");
const ctaElement = document.getElementById("cta2");

const questions2 = [
  {
    question:
      "How would your employees describe internal communication?",
    answers: [
      { text: "Average", correct: true },
      { text: "Needs improvement", correct: true },
      { text: "Not sure", correct: true },
    ],
  },
  {
    question: "How time-efficient is your team?",
    answers: [
      { text: "Average", correct: true },
      { text: "Needs improvement", correct: true },
      { text: "Not sure", correct: true },
    ],
  },
  {
    question: "What do you need help with?",
    answers: [
      { text: "Team Responsibility", correct: true },
      { text: "Internal Communication", correct: true },
      { text: "All of the above", correct: true },
    ],
  },
];


let shuffledQuestions2, currentQuestionIndex2;

startButton.addEventListener("click", startQuiz);
nextButton.addEventListener("click", () => {
  currentQuestionIndex2++;
  setNextQuestion();
  rotateGallery();
});

function startQuiz() {
  startButton.classList.add("hide");
  shuffledQuestions2 = questions2.sort();
  currentQuestionIndex2 = 0;
  questionContainerElement.classList.remove("hide");
  setNextQuestion();
}

function setNextQuestion() {
  resetState();
  if(currentQuestionIndex2 > questions2.length - 1){
    displaySuccess();
    return
  } 
  showQuestion(shuffledQuestions2[currentQuestionIndex2]);
}

function displaySuccess(){
  ctaElement.innerText =
  "Good news! DYL’s Agent Status keeps managers and staff connected at all times. Click below to find out how. Click below to find out how.";
  questionContainerElement.classList.add("hide");
  ctaElement.classList.remove("hide");
  showMeButton.classList.remove("hide");
}


function showQuestion(question) {
  questionElement.innerText = question.question;
  question.answers.forEach((answer) => {
    const button = document.createElement("h5");
    button.innerText = answer.text;
    button.classList.add("btn-block");
    button.classList.add("btn-primary");
    button.classList.add("p-2");
    button.classList.add("transition-3d-hover");
    button.classList.add("text-center");
    if (answer.correct) {
      button.dataset.correct = answer.correct;
    }
    button.addEventListener("click", selectAnswer);
    answerButtonsElement.appendChild(button);
  });
}

function resetState() {
  clearStatusClass(document.body);
  nextButton.classList.add("hide");
  while (answerButtonsElement.firstChild) {
    answerButtonsElement.removeChild(answerButtonsElement.firstChild);
  }
}

function selectAnswer(e) {
  const selectedButton = e.target;
  const correct = selectedButton.dataset.correct;
  setStatusClass(document.body, correct);
  Array.from(answerButtonsElement.children).forEach((button) => {
    setStatusClass(button, button.dataset.correct);
    selectedButton.style.backgroundColor = "green";

  });
    nextButton.classList.remove("hide");
}

function setStatusClass(element, correct) {
  clearStatusClass(element);
  if (correct) {
    element.classList.add("correct");
  } else {
    element.classList.add("wrong");
  }
}

function clearStatusClass(element) {
  element.classList.remove("correct");
  element.classList.remove("wrong");
}

let counter = 0,
  gallery = [
    "https://images.dyl.com/marketing-web/images/quiz/track-runners-taking-off-on-start.jpg",
    "https://images.dyl.com/marketing-web/images/quiz/fingers-pointing-at-man.jpg",
    "https://images.dyl.com/marketing-web/images/quiz/woman-smiling-excited-with-yellow-background.jpg",

  ];
function rotateGallery() {
  document.getElementById("ImgGalleryAS").src = gallery[counter];
  counter++;
  if (counter >= gallery.length) {
    counter = 0;
  }
}

